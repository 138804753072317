import { CdkScrollable } from '@angular/cdk/scrolling';
import { NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  type OnChanges,
  OnInit,
  output,
  type TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { NgScrollbar } from 'ngx-scrollbar';
import { debounceTime } from 'rxjs/operators';

import { CustomFormsModule } from '@clover/custom-forms/custom-forms.module';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { TextboxComponent } from '@design/forms/textbox/textbox.component';
import { TextboxType } from '@design/forms/textbox/textbox.types';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';

export type TableStyle = 'default' | 'minimal';
export type TablePadding = 'small' | 'medium' | 'large' | 'alt';

@UntilDestroy()
@Component({
  selector: 'cc-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgTemplateOutlet,
    CustomFormsModule,
    ReactiveFormsModule,
    ButtonComponent,
    TextboxComponent,
    TooltipDirective,
    TranslateModule,
    CdkScrollable,
    NgScrollbar,
  ],
})
export class TableComponent implements OnInit, OnChanges {
  @Input('ccStyle') style: TableStyle = 'default';

  @Input('ccPadding') padding: TablePadding = 'medium';

  @Input('ccSearchable') searchable = false;

  @Input('ccSearchPlaceholder') searchPlaceholder = 'Search...';

  @Input('ccEmptyRowTemplate') emptyRowTemplate: TemplateRef<unknown> | null = null;

  @Input('ccShowBulkActions') showBulkActions = false;

  @Input('ccSearchDebounceTime') searchDebounceTime = 200;

  @Input('ccStickyHeader') stickyHeader = false;

  @Input('ccSearchFormControl') searchControl: FormControl = new FormControl('');

  searchChange = output<string>();

  @ViewChild('searchBox', { static: false, read: ElementRef })
  searchBox: ElementRef<TextboxComponent> | null = null;

  isSearchEnabled = false;

  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;
  protected readonly TextboxType = TextboxType;

  ngOnInit(): void {
    this.searchControl.valueChanges
      .pipe(untilDestroyed(this), debounceTime(this.searchDebounceTime))
      .subscribe((value) => {
        this.searchChange.emit(value);
      });
  }

  ngOnChanges(): void {
    this.searchable ? this.searchControl.enable() : this.searchControl.disable();
  }

  disableSearch(): void {
    this.isSearchEnabled = false;
    this.searchControl.setValue('');
  }
}
